import * as React from "react"
import {useState} from "react"
import {Button, Slider} from "antd"
import QuizQuestionStyle from "../less/quiz-question.module.less"
import QuestionImage from "../images/quizquestion.png"
import useIntl from "../helpers/useIntl"

const marks = {
  0: "0",
  1000: "1000",
}

const QuizQuestion = ({title, min, max, step, next}) => {
  const intl = useIntl()
  const [value, setValue] = useState(min)

  return (
    <div>
      <div className={QuizQuestionStyle.block}>
        <div className={QuizQuestionStyle.headerImage}>
          <img src={QuestionImage} alt={''}/>
          <h2 className={QuizQuestionStyle.question}>{title}</h2>
        </div>

        <Slider className={QuizQuestionStyle.slider}
                marks={marks}
                value={value}
                step={step}
                onChange={(value) => setValue(value)}
                max={max}
                min={min}/>
      </div>
      <Button color="#00CDB4"
              onClick={() => {
                next(value)
                setValue(0)
              }}
              type="primary">
        {intl.formatMessage({id: "quiz_next-question"})}
      </Button>
    </div>
  )
}


export default QuizQuestion
