import * as React from "react"
import { Fragment, useState } from "react"

import Layout from "../components/layout"
import Container from "../components/container"
import QuizQuestion from "../components/quiz-question"
import { Button } from "antd"
import { graphql } from "gatsby"
import useIntl from "../helpers/useIntl"

import QuizResultStyle from "../less/quizresult.module.less"
import HiddenCssStyle from "../helpers/hiddencss.module.less"

const Quiz = ({ data }) => {
  const intl = useIntl()

  const [currentIndex, setCurrentIndex] = useState(null)
  const [questions, setQuestions] = useState(data.allQuizQuestion.edges)

  let currentQuestion = questions[currentIndex]

  const nextQuestion = (answer) => {
    // save value
    setQuestions([
      ...questions.map((question, index) => {
        return index === currentIndex ? {
          node: {
            ...question.node,
            answer,
          },
        } : question
      }),
    ])

    setCurrentIndex(currentIndex + 1)
  }

  let correctAnswers = questions.reduce((acc, cur) => {
    if (cur.node.answer === cur.node.correct_answer) {

      return acc + 1
    }

    return acc
  }, 0) + "/" + questions.length

  const getTitle = () => {
    if (currentIndex === null) {
      return intl.formatMessage({ id: "quiz_start_header_title" })
    }

    if (currentQuestion) {
      return `${(currentIndex + 1)}/${questions.length}`
    }

    if (currentIndex && !currentQuestion) {
      return intl.formatMessage({ id: "quiz_result_header_title" })
    }
  }

  return (
    <Layout heroBgColor={"#CAE0E7"}
            heroTitle={getTitle()}
            heroTextColor={"#142A7F"}
            heroSize={"small"}>
      <Container size={"small"}>

        {/* START */}
        {(currentIndex === null) && <Fragment>
          <p>{intl.formatMessage({ id: "quiz_start_description" })}</p>
          <Button color="#00CDB4"
                  onClick={() => setCurrentIndex(0)}
                  type="primary">
            {intl.formatMessage({ id: "quiz_start_button" })}
          </Button>
        </Fragment>}

        {/* DURING */}
        {currentQuestion && <QuizQuestion
          index={currentQuestion.node.id}
          title={intl.translate(currentQuestion.node, "title")}
          max={currentQuestion.node.max}
          step={currentQuestion.node.steps}
          min={currentQuestion.node.min}
          next={(value) => nextQuestion(value)}
        />}

        {/* AFTER */}
        {(currentIndex && !currentQuestion) && <div
          className={QuizResultStyle.wrapper}>
          <section className={QuizResultStyle.summary}>
            <h2
              className={HiddenCssStyle.visuallyHidden}>Resultaten</h2> {/* Leave this here for SEO purposes */}
            <div className={QuizResultStyle.decorationDrops}/>
            <div className={QuizResultStyle.content}>
              <div>
                <h2
                  className={QuizResultStyle.title}>{intl.formatMessage(
                  { id: "quiz_result_title" })}</h2>
                <h3
                  className={QuizResultStyle.subtitle}>{intl.formatMessage(
                  { id: "quiz_result_subtitle" })}</h3>
              </div>
              <span className={QuizResultStyle.score}>{correctAnswers}</span>
              <div>
                <span className={QuizResultStyle.share}>
                  {intl.formatMessage({ id: "quiz_result_share" })}
                </span>
              </div>
            </div>
            <div className={QuizResultStyle.decorationDrops}/>
          </section>

          <section className={QuizResultStyle.reflection}>
            <h3>Vergelijk uw resultaten</h3>
            <h3>{intl.formatMessage({ id: "quiz_result_compare_title" })}</h3>
            <span>
                {intl.formatMessage({ id: "quiz_result_compare_title" })}
              </span>
            <div className={QuizResultStyle.legend}>
              <div>
                <span
                  className={QuizResultStyle.own}>
                  {intl.formatMessage({ id: "quiz_result_compare_own" })}
                </span>
              </div>
              <p>
                  <span className={QuizResultStyle.correct}>
                    {intl.formatMessage({ id: "quiz_result_compare_right" })}
                  </span>
              </p>
            </div>
          </section>
        </div>}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allQuizQuestion {
      edges {
        node {
          title_fr
          title_nl
          max
          min
          name
          steps
          correct_answer
          image_large_url
        }
      }
    }
  }
`

export default Quiz
